import React, { useEffect, createContext } from "react"
import { CookiesProvider } from "react-cookie"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/swiper.scss"
import "../styles/globals.scss"
import "../styles/page-styles.scss"
import "../styles/swiper-slider.scss"
import "../styles/tabs.scss"
import "../styles/utils.modules.scss"
import Head from "next/head"
import Router from "next/router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import { useState } from "react"
import ReactGA from "react-ga4"

NProgress.configure({ showSpinner: false });

//Binding events.
Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

export const AreaContext = createContext()
export const GAContext = createContext()

function LamudiWebApp({ Component, pageProps }) {
  const [areaUnit, setAreaUnit] = useState()
  const [GAHash, setGAHash] = useState({})

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GA_TRACKING_ID) {
      ReactGA.initialize([{ trackingId: process.env.NEXT_PUBLIC_GA_TRACKING_ID }]);
    }

    window.OneSignal = window.OneSignal || []
    OneSignal.push(function () {
      OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONE_SIGNAL_ID,
        notifyButton: {
          enable: true,
        },

        allowLocalhostAsSecureOrigin: true,
      })
    })


    
    return () => {
      window.OneSignal = undefined
    }
  }, [])

  return (
    <>
      <Head>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=3.0" />
        <meta name="google-site-verification" content="ILaF-KEPpQo6ScHQZPKW6r4BE8T5CGk3MCWoMlLqD8U" />
        <link rel="manifest" href="/manifest.json" />
        <script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" async></script>
        <script src="/new-relic-script.js" type="text/javascript" async />
      </Head>
      <GAContext.Provider value={[GAHash, setGAHash]}>
        <AreaContext.Provider value={[areaUnit, setAreaUnit]}>
          <CookiesProvider>
            <Component {...pageProps} />
          </CookiesProvider>
        </AreaContext.Provider>
      </GAContext.Provider>
    </>
  )
}

export default LamudiWebApp
